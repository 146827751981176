import { observable, makeObservable, action } from "mobx";

class StackStore {
  index = 0;
  screen = undefined;
  stack = [];

  constructor(config) {
    this.stack = config.stack;
    this.screenProps = config.screenProps;

    this.onClose = () => {
      config.onClose();
    }

    makeObservable(this, {
      index: observable,
      stack: observable,

      goBack: action,
      setStack: action,
      push: action,
    });
  }

  setStack(stack) {
    this.stack.replace(stack);
  }

  push(id, props) {
    if(this.stack[this.stack.length-1]!==id)
      this.stack.push({id, props});
  }
  
  goBack() {
    if(this.stack.length>1)
    {
      this.stack.pop();
    }
  }
}

export default StackStore;