import { useContext } from "react";
import {screen as style} from './style';
import StackContext from "./context";
import { Observer } from "mobx-react";
import ScreenContext from './screenContext';
import OverscrollContain from "components/UI/OverscrollContain/OverscrollContain";

function Screen(props) {
  const store = useContext(StackContext);

  return(
    <ScreenContext.Provider value={{props: {...props}}}>
      <Observer>
        {() => {
          const classes = ['screen'];
          if(store.stack.length>1)
            classes.push('has-back');

          const screenStyle = {width: props.width};
          const overscrollStyle = {};

          if(props.padding)
          {
            if(props.overscroll==='contain') {
              overscrollStyle.paddingTop = props.padding.top;
              overscrollStyle.paddingBottom = props.padding.bottom;
            }else
            {
              screenStyle.paddingTop = props.padding.top;
              screenStyle.paddingBottom = props.padding.bottom;
            }
          }
          
          return (
            <div className={classes.join(' ')} style={screenStyle}>
              {
                props.overscroll==='contain' ? (
                  <OverscrollContain style={{
                    height: "inherit",
                    maxHeight: "inherit",
                    overflowY: "auto",
                    ...overscrollStyle
                  }}>
                    {props.children}
                  </OverscrollContain>
                ) : (
                  props.children
                )
              }
              
              <style jsx>{style}</style>
            </div>
          );
        }}
      </Observer>
    </ScreenContext.Provider>
  )
}

export default Screen;