import css from 'styled-jsx/css'

export default css`
  .stack-holder {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 9999997;
    font-weight: 400;
    color: rgba(var(--menuColor), 1);
  }

  .stack-holder .stack {
    position: relative;
    z-index: 10;
    background-color: rgba(var(--pane1), 1);
    border-radius: 6px;
    min-width: 120px;
    max-width: 90vw;
    max-height: 90vh;
    overflow: hidden;

    border: 1px solid rgba(var(--border1), 1);
    box-sizing: border-box;

    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.15s ease-out, opacity 0.1s ease, width 0.15s ease;

    box-shadow:
    0 4.1px 8px rgba(0, 0, 0, 0.08),
    0 4.3px 7.6px rgba(0, 0, 0, 0.028),
    0 0.5px 12.6px rgba(0, 0, 0, 0.035),
    0 0.9px 25.2px rgba(0, 0, 0, 0.042),
    0 1.7px 47px rgba(0, 0, 0, 0.05),
    0 4px 80px rgba(0, 0, 0, 0.07)
    ;
  }

  .stack-holder .close {
    display: inline-flex;
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: 0px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid rgba(var(--border1), 1);
    border-bottom: 1px solid rgba(var(--border1), 1);
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    color: rgba(var(--modalCloseColor), 1);
    background-color: rgba(var(--modalCloseBg), 1);
    cursor: pointer; 
    z-index: 11;
  }
  .stack-holder .back-holder {
    position: absolute;
    left: 35px;
    top: 45px;
    right: 40px;
    z-index: 11;
    pointer-events: none;
  }
  .stack-holder .back {
    color: rgba(var(--menuColor), 1);
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    border-radius: 100px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0;
    transform: translateX(50px);
    transition: transform .15s ease-out, opacity .15s ease-out;
    box-sizing: border-box;
    pointer-events: none;
  }
  .stack-holder .back:hover {
    background-color: rgba(var(--accentColor), 1);
    color: rgba(var(--menuHoverColor), 1);
  }

  .stack-holder .back.visible {
    opacity: 1;
    transform: translateX(0);
    pointer-events: auto;
  }

  .stack-holder.visible .stack {
    opacity: 1;
    transform: translateY(0);
  }

  .stack .screens-holder {
    overflow: hidden;
    height: inherit;
    max-height: inherit;
  }

  .stack .screens {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: transform .25s ease-out;
    height: inherit;
    max-height: inherit;
  }

  .backdrop {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity .15s ease-out;
  }
  .stack-holder.visible .backdrop {
    opacity: 1;
  }

  .stack :global( .screen > .overscroll-container > .content) {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: inherit;
    box-sizing: border-box;
  }

  .stack :global(h1) {
    font-size: 30px;
    margin: 0px;
    margin-bottom: 15px;
    color: rgba(var(--stackTitleColor), 1);
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .stack :global(h1 + h2),
  .stack :global(h1 + h3),
  .stack :global(h2 + h3) {
    margin-top: -10px;
  }

  .stack :global(h2) {
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 10px;
    color: rgba(var(--stackTitleColor), 1);
  }

  .stack :global(h3) {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 19px;
  }

  .stack:not(.full) :global(.screen > .overscroll-container) {
    margin-top: 0px;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .stack.small:not(.full) :global(.screen > .overscroll-container) {
    margin-top: 40px;
    padding-top: 0px;
    padding-bottom: 40px;
  }
  .stack:not(.full) :global(.screen.has-back .overscroll-container) {
    margin-top: 90px;
    padding-top: 0px;
  }

  .stack.small :global(h1) {
    font-size: 25px;
  }
`;

export const screen = css`
  .screen {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-shrink: 0;

    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
    font-size: 17px;
    max-height: inherit;
    position: relative;
  }

  .screen:not(:last-child) {
    max-height: 0;
  }
`