import React, { useEffect, useState, useRef, useCallback } from 'react';
import style from './style';
import Screen from './Screen';
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons/faChevronLeft";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StackContext from './context';
import StackStore from './store';
import { Observer } from 'mobx-react';
import { toJS } from 'mobx';
import ClientOnlyPortal from 'components/ClientOnlyPortal';
import useIsFrontMost from 'hooks/useIsFrontMost';
import useWindowSize from 'hooks/useWindowSize';

type StackProps = {
  onClose?: () => void;
  initialStack?: InitialStack;
  initialScreenProps?: any;
  allowClose?: boolean;
  closeBackdrop?: boolean;
  overscroll?: "contain" | "auto";
  style?: React.CSSProperties;
  className?: React.ComponentProps<'div'>['className'];
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
}

function Stack(props: React.PropsWithChildren<StackProps>) {
  const ref = useRef();
  const isFrontMostModal = useIsFrontMost();
  const [isVisible, setIsVisibe] = useState(false);
  const children = Array.isArray(props.children) ? props.children : [props.children];
  const initialStack = props.initialStack ? props.initialStack : [{id: children[0].props.id, props: {}}];
  const initialScreenProps = props.initialScreenProps ? props.initialScreenProps : {};
  const windowSize = useWindowSize();
  const store = useRef(new StackStore({
    stack: initialStack,
    screenProps: initialScreenProps,
    onClose: props.onClose,
  })).current;

  const onKeyDown = useCallback((e) => {
    if(!isFrontMostModal)
      return;

    switch(e.keyCode)
    {
      case 27:
        // esc
        // @ts-ignore
        document.activeElement.blur();
        props.onClose();
      break;
    }
  }, [isFrontMostModal]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    requestAnimationFrame(() => {
      setIsVisibe(true);
    });

    window.addEventListener('keydown', onKeyDown, {passive: false});
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    }
  }, [onKeyDown]);

  const onBackdropClick = useCallback(() => {
    if(props.allowClose && props.closeBackdrop) {
      props.onClose();
    }
  }, [props.onClose]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = ['stack-holder'];
  if(isVisible)
    classes.push('visible');  

  return (
    <ClientOnlyPortal selector={'body'}>
      <StackContext.Provider value={store}>
        <div className={classes.join(' ')} ref={ref}>
          <div className="backdrop" onClick={onBackdropClick}></div>
          
          <Observer>
          {
            () => {
              const backClasses = ['back'];
              if(store.stack.length>1)
                backClasses.push('visible');

              const backTitle = (() => {
                const prevScreen = store.stack[store.stack.length-2];
                if(prevScreen) {
                  const prevScreenComponent = children.find(child => child.props.id===prevScreen.id);
                  return prevScreenComponent.props.title;
                }

                return '';
              })();

              const stackStyle = {
                ...props.style
              };
              if(props.height) stackStyle.height = props.height;
              if(props.width) stackStyle.width = props.width;


              // Eventueel kan het huidige screen een andere breedte hebben dan de default
              store.stack.map((screenData) => {     
                screenData = toJS(screenData);                       
                const id = screenData.id;
                const screen = children.find(child => child.props.id===id);
                if(screen.props?.width!==undefined) {
                  stackStyle.width = screen.props.width;
                }
              });

              // Bepaal hoever we opzij moeten schuiven zodat de juiste screen in beeld komt
              let x = 0;
              for(let i=0; i<store.stack.length-1; i++)
              {
                const screenData = toJS(store.stack[i]);
                const id = screenData.id;
                const screen = children.find(child => child.props.id===id);
                const width = Math.min(windowSize.width * 0.9, (screen.props?.width!==undefined ? screen.props.width : props.width));
                x += width;
              }

              const stackClasses = ['stack'];
              props.className && stackClasses.push(props.className);

              return (
                <div className={stackClasses.join(' ')} style={stackStyle}>
                  <div className="back-holder">
                    <div className={backClasses.join(' ')} onClick={store.goBack.bind(store)}>
                      <FontAwesomeIcon icon={faChevronLeft} /> {backTitle}</div>
                  </div>
                  {props.allowClose && <div className="close" onClick={props.onClose}><FontAwesomeIcon icon={faTimes} /></div>}

                    <div className="screens-holder">
                      <div className="screens" style={{
                        transform: `translateX(${0 - x}px)`
                      }}>
                        {
                          store.stack.map((screenData, index) => {     
                            screenData = toJS(screenData);                       
                            const id = screenData.id;
                            const screen = children.find(child => child.props.id===id);
                            const screenProps = screenData.props ? screenData.props : {};

                            if(screen)
                            {
                              let screenWidth = Math.min(windowSize.width * 0.9, (screen.props?.width!==undefined) ? screen.props.width : props.width);
                              if(isNaN(screenWidth)) screenWidth = undefined;

                              const screenCopy = React.cloneElement(screen, {...screenProps});
                              return <Screen key={screen.props.id+'-'+index} {...screen.props} width={screenWidth} screenProps={{...screenProps}} hasFocus={index===store.stack.length-1} overscroll={props.overscroll}>{screenCopy}</Screen>
                            }else
                              <Screen key={"not-found-"+index}></Screen>
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              }
            }
          </Observer>

          <style jsx>{style}</style>
        </div>
      </StackContext.Provider>
    </ClientOnlyPortal>
  )
}

Stack.Context = StackContext;
Stack.defaultProps = {
  closeBackdrop: true,
  allowClose: true,
  overscroll: "contain",
}

export default Stack;